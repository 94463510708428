import React from "react";
import {useIsDesktop} from "@/hooks/useDevice";
import dynamic from "next/dynamic";

const CarouselVariant = dynamic(import('@/components/Partners/CarouselVariant'), {
	loading: () => null,
	ssr: false,
})
const GridVariant = dynamic(import('@/components/Partners/GridVariant'), {
	loading: () => null,
	ssr: false,
})
const AnimatedVariant = dynamic(import('@/components/Partners/AnimatedVariant'), {
	loading: () => null,
	ssr: false,
})

export const VARIANTS = {
	CAROUSEL: 'carousel',
	GRID: 'grid',
	ANIMATED: 'animated'
}


export default function Partners({ data }) {
	const variant = data?.variant || VARIANTS.CAROUSEL
	const isDesktop = useIsDesktop()

	//if not animated always render carousel variant on mobile
	if(!isDesktop && variant !== VARIANTS.ANIMATED){
		return <CarouselVariant data={data} />
	}

	switch (variant){
		case VARIANTS.CAROUSEL:
			return <CarouselVariant data={data} />
		case VARIANTS.ANIMATED:
			return <AnimatedVariant data={data} />
		case VARIANTS.GRID:
			return <GridVariant data={data} />
		default:
			return <CarouselVariant data={data} />
	}
}